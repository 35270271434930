// serviceWorkerRegistration.js
export const register = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then(registration => {
        // console.log('ServiceWorker registered:', registration);
        cacheSpecificApis();
      }).catch(error => {
        console.log('ServiceWorker registration failed:', error);
      });
    });
  }
};

const cacheSpecificApis = async () => { 
  const cache = await caches.open('specific-apis-cache', { cacheMode: 'opaque' });

  const urlsToCache = [
    'api/common/banner',
    'api/common/pages',
    // Add more API endpoints to cache here
  ].filter(url => url !== 'manifest.json');

  await cache.addAll(urlsToCache);
};
